/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components), {Container, Link} = _components;
  if (!Container) _missingMdxReference("Container", true);
  if (!Link) _missingMdxReference("Link", true);
  return React.createElement(Container, {
    className: "text-center vh-100 align-self-center"
  }, React.createElement(_components.h1, null, "404 Eipä Löytynyt"), React.createElement("br"), React.createElement(_components.p, null, "Nytpä kävi sillälailla hassusti että etsimääsi sivua ei löydy."), React.createElement(_components.p, null, "Yleensä kyse on niin sanotusta käyttäjähäiriöstä, mutta jos olet ihan varma että\netsimäsi sivun pitäisi ehdottomasti olla olemassa, olisi hienoa, jos voisit\n", React.createElement(Link, {
    to: "/yhteystiedot"
  }, "ilmoittaa"), " siitä meille (minulle)."), React.createElement(_components.p, null, React.createElement(Link, {
    to: "/"
  }, "Tästä"), " pääset takaisin etusivulle."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
